<template>
    <div id="userInfo">
        <div class="subtitle">个人信息</div>
        <div class="container" v-if="userInfo">
            <div class="item">
                <div class="label">头像</div>
                <div class="content">
                    <img
                        class="avatar"
                        :src="userInfo.avatar || 'https://res.peki.vip/20210105/1609843769476/1609843769477.png'"
                    />
                </div>
            </div>
            <div class="item">
                <div class="label">用户ID</div>
                <div class="content">{{ userInfo.id }}</div>
            </div>
            <div class="item">
                <div class="label">用户昵称</div>
                <div class="content">{{ userInfo.nickname }}</div>
                <div class="opt" @click="isShowEditNicknamePopup = true">修改昵称</div>
            </div>
            <div class="item">
                <div class="label">手机号</div>
                <div class="content">{{ userInfo.telNo }}</div>
                <div @click="isShowEditUserTelPopup = true" class="opt">更换手机号</div>
            </div>
        </div>
        <EditNicknamePopup :isShow.sync="isShowEditNicknamePopup" />
        <EditUserTelPopup :isShow.sync="isShowEditUserTelPopup" :userInfo="userInfo" @success="onBindTel" />
    </div>
</template>
<script>
import EditNicknamePopup from './components/EditNicknamePopup.vue'
import EditUserTelPopup from '@/components/global/EditUserTelPopup'
import { mapGetters } from 'vuex'

export default {
    name: 'userDetail',
    data() {
        return {
            isShowEditNicknamePopup: false,
            isShowEditUserTelPopup: false,
        }
    },
    components: {
        EditNicknamePopup,
        EditUserTelPopup,
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    methods: {
        onBindTel(telNo) {
            this.$toast.success('修改成功')
            this.$store.dispatch('user/updateUserInfo', {
                key: 'telNo',
                val: telNo,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#userInfo {
    .subtitle {
        width: 100%;
        height: 64px;
        line-height: 64px;
        font-size: 16px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e6e6;
        color: #33312f;
        font-weight: 700;
    }
    .container {
        padding: 18px 0 40px;
    }
    .item {
        display: flex;
        align-items: center;
        margin-top: 28px;
        position: relative;
        .label {
            margin-right: 24px;
            width: 95px;
            font-size: 16px;
            color: #99948d;
            line-height: 24px;
            text-align: right;
        }
        .content {
            font-size: 16px;
            color: #33312f;
            line-height: 24px;
            .avatar {
                width: 64px;
                height: 64px;
            }
        }
        .opt {
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);
            width: 96px;
            height: 24px;
            border-radius: 12px;
            border: 1px solid $theme-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $theme-color;
            font-size: 14px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 720px) {
    #userInfo {
        .item {
            .label {
                margin-right: 18px;
                width: 90px;
                font-size: 14px;
            }
            .opt {
                width: 72px;
                font-size: 12px;
            }
        }
    }
}
</style>
